import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Marzo"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/marzo"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/marzo",
        title: "Efemérides - Marzo | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – MARZO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de marzo de 1792:&nbsp;Nació en Salta Rudencio Alvarado, quien participó
      de la revolución de 1810. Actuó con Belgrano y San Martín y luego lo hizo
      con Bolívar. Fue gobernador de Salta.
    </p>
    <p>
      1 de marzo de 1817: Gauchos del ejército del General Martín Miguel de
      Güemes, derrotó a los realistas en León, parte del actual territorio de la
      provincia de Jujuy.
    </p>
    <p>
      1 de marzo de 1817: Nació en Salta, Joaquín M. Díaz de Bedoya. Recibió su
      título de Médico en París, Francia. En Buenos Aires revalidó sus estudios.
      Regresó a Salta. En 1862 fue Ministro General. Diputado Nacional. Se
      incorporó al Ejército al declararse la guerra al Paraguay. Logró el grado
      de Cirujano Mayor concluída la contienda regresó a Salta. Fue rector del
      Colegio Nacional de Salta. Falleció en su tierra natal, el 18 de diciembre
      de 1880.
    </p>
    <p>
      1 de marzo de 1860: Nació en Salta, José Eustaquio Alderete. Se recibió en
      Tucumán de Maestro Normal. Luego dictó clase de Historia, Geografía,
      Matemáticas y Ciencias Naturales en la Escuela Normal de Salta. Fue
      Inspector Nacional de Escuelas. Realizó periodismo. Fue Diputado
      Provincial. Murió en Salta en 1933.
    </p>
    <p>
      1 de marzo de 1900: Inició sus actividades el Colegio Belgrano de la
      ciudad de Salta, dictando clases para 1º, 2º y 3º grado. Este colegio fue
      fundado por la Orden de los Canónigos Regulares de Lateranenses, con
      residencia en Oñate, España. Se establecieron en esta ciudad por gestiones
      realizadas por el Obispo Linares de Salta.
    </p>
    <p>
      1 de marzo de 1922: Nació el doctor Leonardo Gonorazky. Fue socio fundador
      en la Constitución del Instituto Médico de Salta. Fue socio fundador de la
      Sociedad de Ginecología y Obstetricia. Fue presidente del Colegio Médico
      de Salta entre 1965 y 1966. Murió el 26 de marzo de 1984.
    </p>
    <p>
      1 de marzo de 1939: Nació en Salta, Guillermo Petrón. A los 13 años
      ingresó a la Escuela de Bellas Artes “Tomás Cabrera”, de donde sale
      perfeccionado en dibujo, pintura, grabado, escultura y cerámica. Fue
      integrante fundador del Centro de Estudiantes de Bellas Artes (CEBAS). En
      la ciudad de Bahía, Brasil, ingresó a un taller de restauración de
      imaginería portuguesa. En Bolivia, aprendió restauración del Arte Cuzqueño
      y que luego se especializa en Lima en la misma disciplina. Realizó más de
      cien muestras en el país, en las ciudades de Salta, Jujuy, Tucumán,
      Córdoba, Buenos Aires, Mendoza, Santiago del Estero. Expuso en Chile en
      las ciudades de Santiago, Viña del Mar, Valparaíso. En Bolivia lo hizo en
      Cochabanba, Potosí, Sucre, Oruro, La Paz. En Brasil mostró sus trabajos en
      las ciudades de Bahía, Río de Janeiro y San Pablo. Sus trabajos están
      diseminados en el mundo, en Europa, Estados Unidos y gran parte de América
      y de la Argentina, en manos coleccionistas, MUSEOS, escuelas particulares.
      Restauró las pinturas del Convento San Bernardo de Salta, la Iglesia de
      Yavi en Jujuy. Con sus hermanos Angel y Humberto hicieron trabajos de
      restauración en La Posta de Yatasto, en el Fuerte de Cobos, y en la Finca
      La Cruz. Restauró pinturas de la Iglesia San José de la ciudad de Salta y
      la Virgen María del Seminario Conciliar de Salta. Es miembro del Consejo
      Superior de la Universidad Abierta de la Tercera Edad Salta (UNATE).
      Secretario de la Fundación FUNATE y coordinador de 25 talleres artesanales
      que allí funcionan. Es miembro del jurado permanente de los corsos
      salteños. Enseña dibujo y pintura. Desde 1990 escribe coplas, cuentos,
      narraciones y poemas que el autor llama “Petronismos”. Miembro integrante
      de la Comisión de Cultura de FERINOA. Presidente de la Cooperativa
      Integral de Consumo, Servicios Públicos, Colonización, Turismo “Tren a
      Cabra Corral Limitada”. Coordinador de la Sala de Arte de Telecom de Salta
      y de Jujuy. Y actualmente es conductor del programa cultural “Que linda es
      mi Salta”, Radio Visión, así mismo conduce otro programa en FM Omega “Mi
      Salta Bohemia”. Casado con Gladys Osán, con quien tuvo tres hijos y
      conocen la sonrisa de cinco nietos.
    </p>
    <p>
      1 de marzo de 1965: Nació en Salta, Rubén Armando Chammé. Profesor de
      bandoneón de la escuela de Música de la Provincia “José Lo Giúdice”. Como
      músico integró distintos grupos. Actualmente tiene su propio grupo. Es un
      destacado compositor y sus temas son interpretados por conocidos artistas
      de Salta. Colaborador permanente en la revista salteña La Gauchita.
    </p>
    <p>
      1 de marzo de 1981: Se inauguró la repetidora de Canal 11 para Tartagal y
      para el departamento San Martín.
    </p>
    <p>
      1 de marzo de 1996: Se concretó el traspaso y la transferencia efectiva
      del Banco Provincial de Salta S. A. De este modo culminó el proceso de
      privatización de la entidad crediticia y el lunes 4 de marzo abrió las
      puertas al publicó la nueva entidad con sus flamantes autoridades.
    </p>
    <p>
      2 de marzo de 1931: Nació en Salta, Elsa Salfity. Profesora de escultura,
      egresada de la Escuela de Bellas Artes Tomás Cabrera. Artista conocida y
      premiada en distintas ocasiones .
    </p>
    <p>
      2 de marzo de 1953: Nació en Salta, José Humberto Dakak. Es Contador
      Público Nacional y Master en Admiración. Este profesional en Cisncias
      Económicas es Presidente de la Cámara de Comercio e Industria, Vice
      Presidente de la Banca Minorista y Vice Presidente de la empresa Más
      Ventas S.A.C.F.
    </p>
    <p>
      2 de marzo de 1969: El Instituto Provincial de Seguros, fue autorizado por
      Decreto Nº 4.555 a adquirir vacunas para combatir la gripe llamada Hong
      Kong, que hacía estragos en nuestra provincia y en otras regiones.
    </p>
    <p>
      3 de marzo de 1887: Se inauguró en Salta, la Agencia del Banco Hipotecario
      Nacional, que funcionó en primer momento en la calle Caseros al 600, en
      donde se pensó levantar el edificio propio con líneas arquitectónicas
      similares al viejo templo de la Merced.
    </p>
    <p>
      3 de marzo de 1929: Nació en Carlos Casares, Buenos Aires, Juan Martín
      Aguirre. Se ordenó sacerdote. Radicadoen el norte de Salta, fue Vicario
      General de la Diócesis de Orán y cura Párroco de General Mosconi. Figura
      en una antología de Orán.
    </p>
    <p>
      3 de marzo de 1930: El primer cura párroco de Tartagal, padre Angélico
      Scipioni, fundó la Escuela de San Francisco en aquella ciudad.
    </p>
    <p>
      4 de marzo de 1934: Nació en San Andrés, Orán, Salta, Alba Giménez.
      Maestra Normal. Directora de la Escuela Nº 832 de San Andrés. Incluída en
      Antología de Orán.
    </p>
    <p>
      4 de marzo de 1996: El Presidente de la República Argentina, doctor Carlos
      Saúl Menem, inauguró el ciclo lectivo desde Tartagal, en la provincia de
      Salta.
    </p>
    <p>
      5 de marzo de 1813: En este día viernes se declararon los beneméritos en
      alto grado a los vencedores de la Batalla de Salta.
    </p>
    <p>
      5 de marzo de 1817: Gauchos del ejército mandados por el general Martín
      Miguel de Güemes, a cargo del comandante José Gabino de la Quintana,
      derrotaron a los realistas al mando del coronel Martínez, en Comedero de
      Severino, provincia de Salta.
    </p>
    <p>
      5 de marzo de 1921: Se inauguró la Escuela de San Antonio de Orán, con 20
      alumnos. Era dirigida por los Padres Franciscanos. En abril de 1932 fue
      inaugurada, en el mismo local, la Escuela Nocturna San Antonio. En 1947
      comenzó a funcionar allí, la Escuela Fábrica Nº 27, que fue la base de la
      Escuela Nacional de Educación Técnica de Orán.
    </p>
    <p>
      5 de marzo de 1947: Nació en Salta Marcelo Eduardo López Arias. Abogado
      que realizó una brillante carrera política, ocupando importantes cargos en
      la provincia. En la actualidad es Diputado Nacional por Salta, por el
      Partido Justicialista, que lo tiene como uno de sus principales
      dirigentes. Ocupa la vicepresidencia de la Cámara de Diputados de la
      Nación.
    </p>
    <p>
      5 de marzo de 1949: Se fundó la Cruz Roja Argentina filial Metán, adherida
      a la Cruz Roja Internacional.
    </p>
    <p>
      5 de marzo de 1977: Se inició en el Hotel Termas de Rosario de la
      Frontera, provincia de Salta, el XXVIII Campeonato Argentino de Ajedrez
      por Equipos.
    </p>
    <p>
      6 de marzo de 1817: Gauchos del ejército patriota del general Martín
      Miguel de Güemes, derrotaron a los realistas, en San Borja, provincia de
      Salta.
    </p>
    <p>
      6 de marzo de 1913: Se sancionó la ley que dispone la construcción del
      Monumento 20 de Febrero.
    </p>
    <p>
      6 de marzo de 1937: Murió en Salta, don Federico Gauffin. Nació en Salta
      en 1885. Se dedicó al periodismo y a las letras. Publicó: “En tierras de
      Magú-Pelá” (en Buenos Aires en 1932) y luego “Alma Pérdida”. Dos novelas
      de calidad, sobre todo la primera de extraordinario sabor y color
      regional.
    </p>
    <p>
      7 de marzo de 1799: Falleció a la edad de 69 años, Sor María Antonia Paz y
      Figueroa. Admirable dama santiagueña, Señora Beata de los Ejercicios.
      Nació en Santiago del Estero en 1730. A los 15 años se entregó a la vida
      religiosa. Su apostolado la llevó a Catamarca, La Rioja, Salta, Jujuy. En
      1917 se promulgó en Roma el decreto de Introducción de las Causas de
      Beatificación y canonización de la Sierva de Dios.
    </p>
    <p>
      7 de marzo de 1919: Nació en Salta, Danilo Bonari. Se graduó de abogado en
      1944. Dos veces Diputado Provincial. Fue Ministro de la Corte de Justicia
      de la Provincia de Salta. Murió en Salta, el 6 de diciembre de 1971.
    </p>
    <p>
      7 de marzo de 1933: Falleció en Salta Darío Arias, quien había nacido en
      1856. Este abogado fue pionero en las plantaciones de citrus en la
      provincia de Salta. Fue también ministro de Gobierno y Ministro de
      Hacienda en esta provincia.
    </p>
    <p>
      7 de marzo de 1957: Nació en San Lorenzo, Salta, Abel Fleming. Este
      polifacético abogado ocupó importantes cargos en la función pública. Es un
      auténtico gaucho que realiza permanentemente aportes por la cultura
      criolla.
    </p>
    <p>
      8 de marzo de 1816: Nació en Salta, Juan Ramón Muñoz Cabrera. En 1837
      publicó versos contrarios a Rosas, por lo que debió huir a Montevideo. En
      1840 publicó un escrito titulado “Cien Fuegos”. Trabajó en el diario “La
      Epoca” en La Paz, Bolivia. Fundó el periódico: El Talismán y El Telégrafo
      en Perú. Escribió “Rosas y su hija en la quinta de Palermo”, “Rosas y su
      política en los Estados Sudamericanos”. Murió en Lima en 1869.
    </p>
    <p>
      8 de marzo de 1871: Se inauguró el alumbrado a “Kerosene”, por obra de
      Francisco Host en la ciudad de Salta. La materia prima era traída de la
      sierra de La Lumbrera.
    </p>
    <p>
      8 de marzo de 1919: Nació en Chubut, Andrés F. Fidalgo, abogado. Después
      de vivir en Córdoba, Chubut, Entre Ríos, se radicó en Jujuy en 1950.
      Organizó con Jorge Calvetti la filial Jujuy de la Sociedad Argentina de
      Escritores, donde llegó a ser presidente. Participó de encuentros,
      congresos y reuniones. Colaboró con La Gaceta, El Tribuno, El Pregón. Su
      obra “Panorama de la literatura jujeña” fue premiada en el concurso de
      “Ensayos monográficos y crítico – evolutivo, sobre la cultura del
      noroeste, en las jornadas de Historia de la Cultura del NOA en Tucumán.
      Esa obra la editó la Rosa Blindada en 1975. Publicó además: La Copla
      (1958); Elementos de poética (1961 ensayo – Premio del Consejo Editor de
      Tarja); Breve toponomia y vocabulario jujeño (1965); Seis poemas gallegos
      (1966); Toda la voz (1971) y otros.
    </p>
    <p>
      8 de marzo de 1921: Nació en Tucumán, Luis Cano. Sacerdote franciscano.
      Profesor de Filosofía, Letras y Teología. Miembro de número de la Junta de
      Estudios Históricos de Tucumán. Fue Director de Enseñanza de la Provincia.
      Colaboró con diarios y revistas de Argentina, España, Perú y Paraguay.
      Publicó: Esquiú (1961); Palabras del corazón (poesía 1947); Canto de amor
      (Poesía 1950); Acorralado y otros cuentos (1951); El padre lobo (1961);
      Aporte de los franciscanos a la cultura de Tucumán (1971); Orígenes de la
      Escuela de San Francisco de Catamarca (1971); Fray Luis Beltrán, heroico
      defensor de la Nación (1978); Evangelización del Paraguay (1979).
    </p>
    <p>8 de marzo de 1937: Se fundó el Club Argentino de Bochas de Metán.</p>
    <p>
      8 de marzo de 1979: Falleció en Salta Rafael Patricio Sosa, Hombre que
      aportó el conocimiento de la historia salteña. Había nacido el 24 de
      octubre de 1893.
    </p>
    <p>
      9 de marzo de 1814: El capitán Pedro Zabala, del ejército patriota del
      general Martín Miguel de Güemes, derrotó a las tropas realistas, en El
      Carril, provincia de Salta.
    </p>
    <p>
      9 de marzo de 1814: Fuerzas patriotas del ejército mandado por Martín
      Miguel de Güemes, derrotaron al capitán Saturnino Castro, realista, en el
      Valle de Lerma, provincia de Salta.
    </p>
    <p>
      9 de marzo de 1872: Murió en Buenos Aires, Lorenzo Manterola. Nació en
      Salta en 1807. Sumóse a los huestes del general Lavalle en 1829. Actuó
      contra los Restauradores. En 1852 el Gobierno Nacional le reonoció el
      grado de Teniente Coronel y en 1855 Comandante General de Armas.
    </p>
    <p>
      9 de marzo de 1937: Nació en Cafayate, provincia de Salta, “Polo” Román,
      quien desde joven se dedicó a la canción popular. Fue integrante de los
      Puesteros de Yatasto y desde hace más de treinta años es integrante del
      conjunto folklórico salteño Los Chalchaleros.
    </p>
    <p>
      9 de marzo de 1956: Nació en Salvador Mazza, provincia de Salta, Francisco
      Ibañez. Inició su carrera política como concejal, en 1991 resultó electo
      intendente y en el año 1995, fue reelecto para otro período de gobierno.
    </p>
    <p>
      9 de marzo de 1994: A las cinco de la mañana, falleció en Salta el
      recitador costumbrista, El Coya Martín Bustamante. Fue difusor de la obra
      poética-humorística del doctor Julio Díaz Villalba. Actuó en los más
      importantes festivales del país.
    </p>
    <p>
      10 de marzo de 1996: Murió en Salta, el doctor Roberto Alfredo Cruz, a los
      74 años. Fue secretario de Salud Pública y Ministro de Salud Pública en la
      provincia de Salta. Perteneció a la Comisión directiva del Consejo
      Argentino de Alcoholismo (C.A.D.A.), entidad por la que trabajó
      fervorosamente.
    </p>
    <p>
      11 de marzo de 1827: Murió Manuela Martinez de Cangas de Tineo en el
      colegio que fundara y en el que se recluyó tomando el nombre de Manuela la
      esclava de los Sagrados Corazones de Jesús y de María mi señora del
      Rosario, allí descansan sus restos en la caiplla que erigió la comunidad
      que llegó en 1887, de las Hermanas esclavas del Corazón de Jesús como
      fuera deseo de la fundadora. Esta capilla fue proyectada y dirigida por el
      arquitecto alemán Ziegerameyer que estaba en la ciudad de Salta traído por
      sus padres Redentoristas alemanes llegados en 1894 para realizar su misión
      evangelizadora en las más apartadas regiones de la puna Salteña, Jujeña y
      Valles Calchaquíes.
    </p>
    <p>
      11 de marzo de 1899: Nació en Salta, el doctor Atilio Cornejo, estudioso
      que consagró su vida a la historia, siendo merecidamente reconocido por
      todo lo que supo aportar como historiador.
    </p>
    <p>
      11 de marzo de 1919: Nació en Colonia Dora, Santiago del Estero, Carlos
      Sánchez Gramajo. Pintor, escultor. Obtuvo premios de Santa Fe, Santiago
      del Estero, Salta. Expuso en Buenos Aires, Córdoba, Rosario, Tucumán,
      Salta, Jujuy, Catamarca. Presidente de la Asociación de Plásticos
      Santiagueños.
    </p>
    <p>
      11 de marzo de 1976: En este día sucedió la desaparición por secuestro,
      del ex gobernador salteño, el Dr. Miguel Ragone. había sido elegido el 11
      de marzo de 1973, para conducir la provincia de Salta.
    </p>
    <p>
      11 de marzo de 1991: Comenzó a funcionar como establecimiento educativo el
      Colegio Américo Vespucio. Su propietario es el señor Mario Roque Mangini.
      Está habilitado para jardín de infantes, para escuela primaria y
      bachillerato oficial con computación en el secundario. Desde 1993 se
      agregó un secundario comercial que otorga el título de perito mercantil.
      Desde 1994 los adultos podran recibir, luego de tres años de estudios, el
      título de perito mercantil. Para el terciario esta casa de estudios posee
      dos carreras: Comercio Exterior y Profesorado de Inglés.
    </p>
    <p>
      12 de marzo de 1817: El comandante José Apolinario Saravia, del ejército
      patriota del general Martín Miguel de Güemes, derrotó a los realistas
      mandados por el coronel Jerónimo Valdés, en Jujuy.
    </p>
    <p>
      12 de marzo de 1817: El capitán José Francisco Gorriti, del ejército del
      General Martín Miguel de Güemes, derrotó a los realistas, en Río Chico,
      provincia de Salta.
    </p>
    <p>
      12 de marzo de 1868: César Niño recibe una tinaja de grandes dimenciones
      en tierras cafayateñas. Esta tinaja estuvo por muchos años al frente de la
      casa popular César F. Perdiguero, el Cochero joven, y hoy, 130 años
      después ocupa un lugar importante en la casa de su hijo César Sergio
      Perdiguero, que está ubicada en la bella localidad de Campo Quijano, El
      Portal de Los Andes.
    </p>
    <p>
      12 de marzo de 1901: Se fundó en la ciudad de Salta, el Club Atlético
      Libertad. Fue la idea de un grupo de anarquistas, la que determinó la
      fundación de este Club, decano de las entidades salteñas.
    </p>
    <p>
      12 de marzo de 1945: Se creó el Escuadrón Nº 20 de Orán, de Gendarmería
      Nacional, en la provincia de Salta.
    </p>
    <p>
      12 de marzo de 1953: Fue rehabilitada la Agrupación Salta de Gendarmería
      Nacional. Su asiento en la ciudad de Salta. Fue creada en 1945 y
      desactivada en 1948. Su primer jefe fue el Comandante Luis César Ramírez.
    </p>
    <p>
      12 de marzo de 1957: Nació en Rosario, Roque Mascarello. En 1981 se
      recibió de médico en la Universidad de Córdoba. Fue director del Hospital
      de San Antonio de San Antonio de los Cobres (1981 a 1984) y del Hospital
      Dr. Joaquín Castellanos de General Güemes (1984 a 1989). Fue jefe del
      Programa de Atención Primaria de la Salud del Ministerio de Salud Pública
      y Director de Planificación. Médico auditor del Instituto de Servicios
      para jubilados y pensionados. Delegado de la Región 12 de PAMI. Docente y
      coordinador de diversos cursos. El doctor Juan Carlos Romero, lo designó
      Secretario de Salud Pública.
    </p>
    <p>
      13 de marzo de 1817:&nbsp;El capitán Olivera, del ejército de Güemes,
      derrotó a los realistas en Río Chico, provincia de Salta.
    </p>
    <p>
      13 de marzo de 1922: Nació en Salta, Eduardo Francisco Remy Solá, quien
      logró medalla de oro como egresado del Colegio Nacional de Salta. Se
      recibió de médico en la Universidad Nacional de Buenos Aires en 1947 y dos
      años después se doctoró en medicina. Se radicó definitivamente en Buenos
      Aires. Recibió el Premio Edgardo Nicholson otorgado por la Academia de
      Medicina de Buenos Aires. La Secretaría de Cultura de la Nación le otorgó
      el primer premio a su trabajo sobre temas médicos. Docente en la
      Universidad Nacional de Buenos Aires. Se desempeñó en el Hospital
      Rivadavia y en el Hospital de Clínicas, ambos en Capital Federal.
    </p>
    <p>
      14 de marzo de 1817:&nbsp;El sargento mayor Marín, del ejército del
      general Martín Miguel de Güemes, derrotó a los realistas, en Molinos,
      provincia de Salta.
    </p>
    <p>
      14 de marzo de 1817: El capitán Vicente Torino, del ejército de gauchos
      patriotas de Güemes, derrotaron al batallón realista , escolta del
      brigadier José La Serna, en Alto del Pasteadero, provincia de Salta.
    </p>
    <p>
      15 de marzo de 1817: Los gauchos del coronel Güemes, lograron un gran
      triunfo en Orán, sobre las tropas realistas que mandaba Marquiegui y el
      mismísimo general Olañeta.
    </p>
    <p>
      15 de marzo de 1870: Nació en Salta, Francisco Ortíz. Abogado. Juez del
      Crimen. Cónsul General en Japón. Falleció en Buenos Aires.
    </p>
    <p>
      15 de marzo de 1875: Falleció en Salta, el doctor Vicente Arias y Arias
      quien nació en Salta en 1807. Fue médico personal del gobernador de
      Santiago del Estero, don Juan Felipe Ibarra. Recorrió la campaña
      santiagueña a caballo, asistiendo a los enfermos.
    </p>
    <p>
      15 de marzo de 1943: Abrió sus puertas en la ciudad de Salta, el Instituto
      José Manuel Estrada, de enseñanza media, creado por el sacerdote José Mir,
      quien era su director.
    </p>
    <p>
      16 de marzo de 1795:&nbsp;Nació en San Carlos, provincia de Salta, Benita
      Carrillo de Sosa, quien en 182 fundó la Escuela Privada de Francés y
      Música y luego abrió una escuela de primeras letras, en San Carlos. Murió
      el 2 de enero de 1880.
    </p>
    <p>
      16 de marzo de 1914: El Consejo Municipal de Metán, acepta la propuesta
      del Señor Carlos Maurel, para el alumbramiento público “a razón de 83
      centavos por luz y por noche, por cada luz que encienda, debiendo ser 5 en
      Villa San José y 7 en Villla Estación Metán”. La Municipalidad proveerá de
      la lámpara con presión para prender y carro para el reparto de nafta. Las
      luces deberán encenderse “Veinte noches en cada mes y durará seis horas
      desde el 1º de abril, debiendo a las 12 de la noche y desde octubre una
      hora más tarde.
    </p>
    <p>
      17 de marzo de 1773: En Salta, el gobernador Jerónimo de Matorras envió al
      Virrey Vértiz un oficio sugiriendo la necesidad de poner en las gargantas
      de Jujuy oficiales reales, eficientes y honrados que no protejan el
      contrabando de negros provenientes de la Colonia Sacramento…”
    </p>
    <p>
      18 de marzo de 1814: El general Martín Miguel de Güemes, derrotó con sus
      gauchos, la vanguardia del ejército realista, que mandaba al general
      Joaquín de la Pezuela y que estaba a cargo del general Saturnino Castro,
      en Guachipas, provincia de Salta.
    </p>
    <p>
      18 de marzo de 1814: El capitán Gabino Sardina, del ejército patriota del
      general Martín Miguel de Güemes, derrotó a los realistas y toma la plaza,
      en San Bernardo, provincia de Salta.
    </p>
    <p>
      18 de marzo de 1908: Nació el doctor José María García Bes. Se doctoró en
      Medicina en 1932. Se incorporó al Ejército, llegando al grado de Coronel.
      Fue Inspector General de Sanidad Militar. Fue Ministro de Salud Pública
      desde 1955 hasta el año 1960. Murió el 4 de marzo de 1969.
    </p>
    <p>
      18 de marzo de 1939: Nació en la provincia de Jujuy, Gabriel Antonio
      Calderón. Escritor y poeta fundador de la Peña de la Amistad. Publicó:
      Emociones de la tierra (compartido); Canto al Cabra Corral (compartido);
      Recuerdo… y casi un adiós…; Versos en la tarde (que fue presentado en la
      Feria del Libro de Buenos Aires, en Jujuy y en Salta). Sus poemas fueron
      musicalizados por Hugo Cabana Flores, Juan Vizgarra y otros músicos
      populares. Fue premiado en Orán, Santa Fe y en Buenos Aires.
    </p>
    <p>
      18 de marzo de 1942: Nació en Salta, el poeta Hugo Alarcón, quien publicó
      junto a Martín Adolfo Borelli y Antoniio Vilariño un libro de poemas.
      Aportó su capacidad creadora al cancionero argentino. Tuvo destacada
      actuación en programas radiales y televisivos. Además se destacó como
      animador de espectáculos folklóricos.
    </p>
    <p>
      18 de marzo de 1963: Nació en Orán, Víctor J.Naser. Ganó el 2º premio del
      concurso literario en Orán en 1980. Figura en Antología de Orán.
    </p>
    <p>
      19 de marzo de 1872: Se autorizó a don Pedro Lary-Storch y Cía. establecer
      el Banco de la Provincia, base del posterior Banco de la Provincia de
      Salta.
    </p>
    <p>
      19 de marzo de 1962: Falleció el músico Adolfo “Payo” Solá. Sus cenizas
      fueron trasladadas a Cafayate, su pueblo natal, en 1974. El escenario de
      la Bodega Encantada donde se realiza la Serenata a Cafayate, lleva su
      nombre como reconocimiento y homenaje.
    </p>
    <p>
      20 de marzo de 1816:&nbsp;El comandante Rafael Hortiguera, perteneciente
      al ejército del Norte que mandaba al general José Rondeau, es derrotado
      por los comandantes Eustaquio Moldes y Vicente Panama, pertenecientes al
      ejército salteño del general Martín Miguel de Güemes, en Campo Santo,
      provincia de Salta.
    </p>
    <p>
      20 de marzo de 1902: Nació en Salta, Jaime Durán. Realizó el secundario en
      el Colegio Salesiano de Salta. Estudió farmacia. Se inició como empleado
      de la farmacia Sudamericana de Salta. Trabajó en Huaytiquina en la obra
      ferroviaria transandina. Adquirió la Farmacia Alemana y luego se fue
      concretando una cadena de farmacias, hasta llegar a tener un laboratorio
      propio, intervino en la organización de otras empresas: Cerámica Salteña,
      Productos Momy, Compañía Industrial Cervecera, Diario El Tribuno. Adquirió
      la hacienda de Castañares. Fue presidente de la Cámara de la Producción,
      Presidente de la Confederación General Económica de Salta. Fue Ministro de
      Economía de Salta. Donó 42 Hectáreas para el emplazamiento de la
      Universidad Católica de Salta. Proyectó y concretó el barrio “Ciudad del
      Milagro”. Falleció el 7 de marzo de 1977.
    </p>
    <p>
      20 de marzo de 1960: Nació en Salta, Gustavo Rübens Agüero, quien publicó,
      los siguientes libros de poemas: “Resurrección de la soledad”, “Huésped
      sin regreso”, “Santa Ana de Yacuma”, además de plaquetas y diversas
      publicaciones colectivas. Fue distinguido con numerosos premios
      provinciales y de convocatoria nacional. Colabora en diarios, revistas,
      semanarios. Se dedica a la docencia.
    </p>
    <p>
      21 de marzo de 1870: Nació en Salta Benita Campos. Destacada docente que
      también abrazó la actividad periodística, dirigiendo la revista Güemes.
      Realizó importantes aportes a la Cultura de Salta. Murió el 3 de julio de
      1928.
    </p>
    <p>
      21 de marzo de 1903:&nbsp;En inmediaciones del Puente Blanco, en la ciudad
      de Salta, es asesinada Juana Figueroa. (El Puente Blanco estaba ubicado en
      la intersección de las actuales Avenida Hipólito Yrigoyen y la calle Pedro
      Pardo).
    </p>
    <p>
      21 de marzo de 1911:&nbsp;Nació en Cieneguillasm, departamento de Santa
      Catalina, provincia de Jujuy, Aristóbulo Wayar. En 1938 se radicó en
      Salta, donde ejerció el periodismo en diario El Tribuno y otros medios. Lo
      distinguieroncon el Premio “Gran Maestro del periodismo”.
    </p>
    <p>
      22 de marzo de 1816:&nbsp;Firmaron el Pacto de los Cerrillos, el
      gobernador-intendente de Salta, don Martín Miguel de Güemes y el general
      Rondeau, a cargo del Ejército al Alto Perú.
    </p>
    <p>
      22 de marzo de 1904: Se le concedió al Club Gimnasia y Tiro de Salta
      (recientemente fundado), las instalaciones del antiguo Polígono de la
      Asociación de tiro Federal, que había desaparecido, al igual que la Liga
      Patriótica, su sucesora.
    </p>
    <p>
      22 de marzo de 1925: Murió en Salta, Moisés J. Oliva. Docente que fue
      presidente del Consejo General de Educación y rector del Colegio Nacional
      de Salta. Gobernador interino, Diputado Nacional por Salta. Fundó y
      dirigió El Orden y fue redactor de El Bien Público.
    </p>
    <p>
      22 de marzo de 1956: Nació en Santa Rosa de Conlara, provincia de San
      Luis, Hernández Jorge Sánchez, que se recibio de médico en 1981, en la
      Universidad Nacional de Buenos Aires. Se desempeñó como ayudante de la
      primera cátedra de Anatomía en la Universidad Nacional de Buenos Aires,
      luego en el Hospital Juan A. Fernández de la Capital Federa. Seradicó en
      Salta en 1989, prestando sus servicios en el Hospital San Bernardo y en el
      Instituto Médico de Salta. Logró premios y becas.
    </p>
    <p>
      23 de marzo de 1807:&nbsp;El Papa confirmó el cese de Monseñor Nicolás
      Videla del Pino en el Obispado del Paraguay y su designación en el
      Obispado de Salta.
    </p>
    <p>
      23 de marzo de 1814: El general Martín Miguel de Güemes, al frente de sus
      gauchos, derrotó a los realistas, mandados por el coronel Saturnino
      Castro, en Cuesta de la Pedrera, provincia de Salta.
    </p>
    <p>
      23 de marzo de 1823: Fue introducida en Salta, durante el gobierno del
      brigadier José Ignacio Gorriti, la vacuna descubierta por Pasteur.
    </p>
    <p>
      23 de marzo de 1943: Nació en Salta, Rodolfo Francisco Lucci Aredes. Es un
      reconocido vetrílocuo, que desde hace 40 años divierte a grandes y chicos
      con su famoso Muñeco Pepito. Además hace figuras con globos que se las
      reparte a los niños en sus actuaciones o bien con el papel y una tijera.
      Es coleccionista de moneds, estampillas, tarjetas. Visita escuelas en el
      interior de las provincias de Salta y Jujuy, llevándole a los chicos,
      además de su actuación sin cargo, grandes cargamentos con ropa, útiles
      escolares, golosinas, calzado, juguetes. Es una tarea digna de ser
      imitada. Artísticamente se lo llama Rodolfo Aredes.
    </p>
    <p>
      23 de marzo de 1983:&nbsp;Visitó la Biblioteca Popular Juan Carlos Dávalos
      de la ciudad de Salta, el Premio Nobel de la Paz, el arquitecto argentino
      don Adolfo Pérez Esquivel.
    </p>
    <p>
      23 de marzo de 1985: Falleció el Arzobispo de Salta, Monseñor Carlos
      Mariano Pérez.
    </p>
    <p>
      24 de marzo de 1857: Murió en Salta, Lorenzo Maurín. Perteneció a las
      fuerzas de Güemes. Participó en los combates de Molinos, Pampas de las
      Carreras y Yavi. Güemes lo ascendió a Sargento Mayor. Estuvo en la defensa
      de Salta contra la invasión de Orozco en 1820. El 7 de junio de 1821 cayó
      prisionero de los realistas y fue cangeado por otros prisioneros.
      Contribuyó a la construcción del templo de la primitiva iglesia de La
      Candelaria de La Viña, donde fueron inhumados sus restos.
    </p>
    <p>
      24 de marzo de 1889:&nbsp;Se autorizó al Ejecutivo a vender en remate
      público el terreno y edificio del Cabildo de Salta, siendo los fondos
      obtenidos destinados a la construcción del Palacio Legislativo.
    </p>
    <p>
      24 de marzo de 1911: Se creó la Escuela Primaria de Las Juntas, provincia
      de Salta, posteriormente la Escuela Nº 592 de Nogalito, Yatasto. Su
      primera directora fue la señorita Laura Torres. Comenzó con 84 añumnos y
      en 1991 tenía 38 alumnos.
    </p>
    <p>
      24 de marzo de 1925:&nbsp;Falleció en Buenos Aires el doctor Luis Aráoz.
      Nació en Salta en 1884. Residió gran parte de su vida en Tucumán. En
      Buenos Aires se recibió de abogado. Ejerció su profesión en Tucumán,
      provincia a la que presentó con su banca de Diputado Nacional. Se quedó en
      Buenos Aires, donde se lo designó Intedente de la ciudad de Buenos Aires.
    </p>
    <p>
      25 de marzo de 1814: El general Martín Miguel de Güemes, derrotó las
      tropas realistas, en Río Pasaje, provincia de Salta.
    </p>
    <p>
      25 de marzo de 1817: El comandante Bartolomé de la Corte, del ejército del
      general salteño Martín Miguel de Güemes, derrotó a los realistas, en Río
      Grande, provincia de Jujuy.
    </p>
    <p>
      25 de marzo de 1817: El capitán Benavídez, del ejército de gauchos
      salteños de Güemes, derrotó a los realistas, en Orán, provincia de Salta.
    </p>
    <p>
      25 de marzo de 1971:&nbsp;Murió en Salta José Lo Giudice. Escribió la
      música de los himnos del Bachillerato Humanista Moderno y de la
      Universidad Católica de Salta. Gran parte de su producción musical se
      encuentran en el Archivo Histórico de la Provincia.
    </p>
    <p>
      25 de marzo de 1983: Fue fundado el Sindicato de Estaciones de Servicios,
      Gomerías, Lavaderos, Garages, Guarderías y Playas de Estacionamientos.
      Cuenta con delegaciones en Güemes, Metán, Rosario de la Frontera, Joaquín
      V. González, Tartagal, Orán y Valle de Lerma.
    </p>
    <p>
      26 de marzo de 1812:&nbsp;El general doctor Manuel Belgrano, se hace cargo
      del ejército del Norte, que la entrega el brigadier Juan Martín de
      Pueyrredón, en Yatasto, provincia de Salta.
    </p>
    <p>
      26 de marzo de 1825:&nbsp;Se creó la Policía de la Provincia de Salta.
    </p>
    <p>
      26 de marzo de 1947:&nbsp;Se estableció L.V. 9 Radio Provincia de Salta.
    </p>
    <p>
      27 de marzo de 1761: El Procurador General de estas jurisdicciones,
      sargento mayor Leonardo Valdez, feudatario en la ciudad de Salta, pero que
      vivía en tierras catamarqueñas, pidió al Ilustre Cabildo de la ciudad que
      realice la información jurada de los prodigios y origen de la imagen de
      Nuestra Señora del Valle.
    </p>
    <p>
      27 de marzo de 1812: El general Manuel Belgrano se hizo cargo del Ejército
      Auxiliar en Yatasto, Salta y al día siguiente esta fuerza comenzó de nuevo
      a avanzar de nuevo hacia Jujuy por cuanto se sabía que el enemigo estaba
      detenido en el Alto Perú. La concentración de todas las divisiones tuvo
      lugar en Campo Santo (Salta), pero el 25 de mayo ya estaba todo el
      ejército en la ciudad de Jujuy, donde fue solemnemente conmemorado el 2º
      aniversario del Movimiento de Mayo.
    </p>
    <p>
      27 de marzo de 1821: El general José Ignacio Gorriti, gobermador delegado
      de Salta, derrotó completamente al coronel Guillermo Marquiegui, realista,
      quien se vió obligado a rendirse en Jujuy. Esta jornada es llamada: el día
      grande de Jujuy.
    </p>
    <p>
      27 de marzo de 1947:&nbsp;Nació en Salta, el poeta Leopoldo Castilla.
      Publicó entre otras obras: “El espejo de fuego”, “Generación terrestre”.
      Hijo del poeta Manuel J. Castilla. Está radicado en Europa.
    </p>
    <p>
      28 de marzo de 1806: El Papa Pío VII creó la Diócesis de Salta. Atendía a
      la intendencia del Tucumán y se le incorporaba Tarija.
    </p>
    <p>
      29 de marzo de 1814: Tropas patriotas mandadas por el general Martín
      Miguel de Güemes, derrotaron a los realistas mandados por el coronel
      Saturnino Castro, en Tuscal de Velarde, provincia de Salta.
    </p>
    <p>
      29 de marzo de 1868: Nació en Salta, Fernando López. Abogado y docente.
      Publicó un opúsculo titulado “Los Privilegios Parlamentarios”. Murió el 27
      de abril de 1915.
    </p>
    <p>
      29 de marzo de 1928: nació en San Salvador de Jujuy, Jorge Hugo Chagra. Es
      un profesional de la salud que se desempeña en el Ejército Argentino. Es
      compositor de temas de folklore argentino que son cantados por muchos
      intérpretes. Ha grabado con su voz, bellas páginas de su autoría. También
      es un pintor autodidacta, que expuso en Salta, Jujuy y Tartagal. Está
      radicado en la ciudad de Salta.
    </p>
    <p>
      29 de marzo de 1958: Nació en Metán, Raqel Escudero. Poeta que publicó
      cuatro libros de poemas: Campo Abierto, A. Galope, Prontuario y Nunca dar
      con el jamás de tanto siempre. Es autora, también, del libro de cuentos:
      Oscuro tan Obscuro.
    </p>
    <p>
      30 de marzo de 1817: El capitán Alejandro Burela, perteneciente al
      ejército de Güemes, derrotó a los realistas en Abra de Zenta, provincia de
      Salta.
    </p>
    <p>
      31 de marzo de 1840: Nació en Salta, Benjamín Olmos. Se ordenó sacerdote
      en 1867. Fue capellán del Coro de la iglesia Catedral. Organizó la
      Comisión para hacer frente a la epidemia de cólera. Falleció en 1893.
    </p>
    <p>
      31 de marzo de 1923: Nació en la ciudad de Salta, el poeta Raúl Aráoz
      Anzoátegui. En 1937 publicó por primera vez en un diario de Salta. En 1940
      publica por peimera vez en una revista de Buenos Aires. En 1941 gana su
      primer premio en Jujuy, en Juegos Florales, otorgado por el gobierno de
      Jujuy, con su elegía a Lavalle. En el suplemento que dirigía Eduardo
      Mallea en el diario La Nación se publicó una colaboración en verso en el
      año 1942. Su actividad siguió desde entonces hasta el presente. Integró el
      grupo La Carpa con destacados poetas del noroeste argentino. En 1945
      aparece su primer libro: “Tierras altas”, luego, “Rodeados vamos de rocío”
      (1963), “Pasar la vida” (1974); “Poemas hasta aquí” (1967), en 1985
      aparece su antología “Obra poética”. También había publicado “Tres Ensayos
      de la Realidad” y algunas plaquetas. Logró el Premio Regional en 1948 en
      1977 el 2º Premio Regional de poesía. Recibió también el Gran Premio de
      honor de la Fundación Argentina para la poesía correspondiente al año 1981
      y otros premios y reconocimientos. Fue Director de Turismo y Cultura de la
      provincia de Salta, subdirector del diario Norte, director de Radio
      Nacional Salta, Director de ATC, Delegado del Fondo Nacional de las Artes.
      Miembro del jurado de muchos concursos.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
